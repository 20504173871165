@import '../../../scss/index.scss';

.table-row-light {
   background-color: #ffffff;
}
.table-row-dark {
   background-color: #b8b7b7;
}

.table-striped-rows tr:nth-child(2n) td {
   background-color: #f5f3f3;
}
.table-striped-rows thead {
   background-color: #f1f1f1;
}

.btn-delete {
   background-color: $background-red;
   border-radius: 4px;
   width: 120px;
   height: 35px;
   transition: 0.3s;
   font-size: 14px;
   color: white;
   margin-left: 20px;

   &:hover {
      background-color: lighten($color: $background-red, $amount: 10);
      color: white;
      border-color: $background-red;
   }

   &:focus {
      background-color: lighten(
         $color: $background-red,
         $amount: 10
      ) !important;
      color: white !important;
      border-color: $background-red !important;
   }

   &:active {
      background-color: lighten($color: $background-red, $amount: 10);
      color: white;
      border-color: $background-red;
   }
}
.footer-table {
   display: flex;
   justify-content: flex-end;
}
.btn-delete-mobile {
   background-color: $background-red;
   border-radius: 4px;
   width: 95px;
   height: 35px;
   // transition: 0.3s;
   font-size: 11px;
   color: white;
   margin-left: 5px;

   &:hover {
      background-color: lighten($color: $background-red, $amount: 10);
      color: white;
      border-color: $background-red;
   }

   &:focus {
      background-color: lighten(
         $color: $background-red,
         $amount: 10
      ) !important;
      color: white !important;
      border-color: $background-red !important;
   }

   &:active {
      background-color: lighten($color: $background-red, $amount: 10);
      color: white;
      border-color: $background-red;
   }
}