// @import '../../../scss/index.scss';

.table-row-white {
   background-color: #ffffff;
 }
 .table-row-blue {
   background-color: #bbc7ff5a;
 }
 .table-row-blue:hover td{
   background-color: #bbc7ff4b !important;
 }

 .btn-row-color{
   border-color: #bbc7ff5a;
   background: #bbc7ff5a;
   border-radius: 5px;
   margin: 10px;
 }

 .p-font{
   margin-top: 15px;
   font-weight: 500;
 }

 
