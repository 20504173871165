@import '../../../scss/index.scss';

.menu-top {
    display: flex;

    button {
        color: $font-light;

        &:hover {
            color: $primary-color;
        }
        &:focus,
        &:active {
            color: $font-light;
        }
    }

    &__left {
    }

    &__right {
        position: absolute;
        right: 0;

        .ant-dropdown-link {
            color: $font-light;
            font-size: 16px;
            margin-right: 20px;
            text-transform: capitalize;
        }
    }
}

.active-scroll{
    overflow-y: scroll;
    height: 166px;
} 