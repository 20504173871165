.container-reference-gray{
    background-color: #F1F5F7;
    padding: 10px 20px 0px 20px;
}
.container-reference{
    padding: 10px 20px 0px 20px;
}
.border-line-{
    border-top-color:#d9dcde;
    border-top-width:1px;
    border-top-style:solid;
    border-bottom-color:#d9dcde;
    border-bottom-width:1px;
    border-bottom-style:solid;
}
.border-line-top{
    border-top-color:#d9dcde;
    border-top-width:1px;
    border-top-style:solid;
}
.border-line-bottom{
    border-bottom-color:#d9dcde;
    border-bottom-width:1px;
    border-bottom-style:solid;
}