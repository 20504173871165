.container-billing-gray{
    background-color: #F1F5F7;
    padding: 10px 20px 0px 20px;
}


.category-button{
    
    .ant-select:not(.ant-select-customize-input) .ant-select-selector{
        background-color: #316578;
        color:white;
    }
}
