@import '../../scss/index.scss';

.admin {
    &__row {
        display: flex;
        align-items: center;
    }
    &__container {
        background-color: white;
        padding: 20px;
        border-radius: 4px;
        box-shadow: 0 3px 6px $font-grey-light;
        margin-bottom: 20px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-amount {
        .ant-card.ant-card-bordered {
            margin: 0px 20px 20px 20px;
            border: 1px solid #dce6eb;
            border-radius: 4px;
            box-shadow: 0 2px 6px $font-grey-light;
            cursor: pointer;
        }
        &__circule {
            height: 50px;
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            color: white;
            font-size: 24px;
            font-weight: 600;
        }

        &__circule.green {
            background-color: $background-green;
        }

        &__circule.orange {
            background-color: $background-orange;
        }

        &__circule.red {
            background-color: $background-red;
        }

        &__price {
            font-size: 20px;
            font-family: 'Roboto';
            font-weight: 400;
            text-align: right;
            line-height: 1.2;
            margin-bottom: 0px;
            text-transform: uppercase;
        }

        &__price.green {
            color: $font-green;
        }

        &__price.orange {
            color: $font-orange;
        }

        &__price.red {
            color: $font-red;
        }

        &__pays {
            text-align: right;
            font-size: 14px;
            color: $primary-color;
            text-transform: uppercase;
        }
    }
}

@media only screen and (max-width: $media-breackpoint-up-sm) {
    .admin__container {
        padding: 20px 10px;
    }
}
