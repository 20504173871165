@import '../../../scss/index.scss';

.btn-vigencia {
    width: 150px;
    font-size: 16px;
}

.btn-vigencia-green {
    background-color:  lighten($color: $border-green, $amount: 10) !important;
    border-radius: 10px;
    border-color:  lighten($color: $border-green, $amount: 10);
    color: white;

    &:hover {
        background-color: lighten($color: $border-green, $amount: 10);
        color: white;
        border-color: $border-green;
    }

    &:focus {
        background-color: lighten($color: $border-green, $amount: 10) !important;
        color: white !important;
        border-color: $border-green !important;
    }

    &:active {
        background-color: lighten($color: $border-green, $amount: 10);
        color: white;
        border-color: $border-green;
    }
}

.btn-vigencia-yellow {
    background-color:  lighten($color: $yellow, $amount: 10) !important;
    border-radius: 10px;
    border-color:  lighten($color: $yellow, $amount: 10);
    color: black;

    &:hover {
        background-color: lighten($color: $yellow, $amount: 10);
        color: black;
        border-color: $yellow;
    }

    &:focus {
        background-color: lighten($color: $yellow, $amount: 10) !important;
        color: black !important;
        border-color: $yellow !important;
    }

    &:active {
        background-color: lighten($color: $yellow, $amount: 10);
        color: black;
        border-color: $yellow;
    }
}

.btn-vigencia-red {
    background-color:  lighten($color: $background-red, $amount: 10) !important;
    border-radius: 10px;
    border-color:  lighten($color: $background-red, $amount: 10);
    color: white;

    &:hover {
        background-color: lighten($color: $background-red, $amount: 10);
        color: white;
        border-color: $background-red;
    }

    &:focus {
        background-color: lighten($color: $background-red, $amount: 10) !important;
        color: white !important;
        border-color: $background-red !important;
    }

    &:active {
        background-color: lighten($color: $background-red, $amount: 10);
        color: white;
        border-color: $background-red;
    }
}