@import '../../../scss/index.scss';

.container-fecha{
    display: grid;
    align-items: end;
    justify-content: end;
}
.display-end {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 400px;
}