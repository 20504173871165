@import '../../../../scss/index.scss';

.add-catalog-form{

   .dynamic-delete-button {
      font-size: 20px;
      margin-left: 10px;
      color: $danger;
   }

}