@import '../../../scss/index.scss';

.modules{
    &__container {
        justify-content: center;
        display: flex;
        align-items: center;
    }
    &__button {
        background-color: #052B43;
        border-radius: 8px;
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
        height: 180px;
        width: 80%;
        white-space: normal;
        h3{
            color: white;
        }
    }
    &__button:hover {
        background-color: darken($color: #052B43, $amount: 2);
        border-color: #052B43;
        height: 180px;
        width: 80%;
        transform: scale(1.05);
        h3{
            color: lightgrey;
        }
    }
    &__button:focus {
        background-color: #052B43;
        height: 180px;
        width: 80%;
    
        h3{
            color: lightgrey;
        }
    }
    &__button-inactive {
        background-color: $danger;
    }
}