@import '../../../../../../scss/index.scss';

.new-line-form {
  .text-label {
    font-size: 14px;
  }
}

.btn-cleanner {
  background-color: $background-blue-dark;
  border-radius: 4px;
  width: 170px;
  height: 40px;
  transition: 0.3s;
  font-size: 16px;
  color: white;
  margin-left: 10px;

  &:hover {
      background-color: lighten($color: $background-blue-dark, $amount: 10);
      color: white;
      border-color: $background-blue-dark;
  }

  &:focus {
      background-color: lighten($color: $background-blue-dark, $amount: 10) !important;
      color: white !important;
      border-color: $background-blue-dark !important;
  }

  &:active {
      background-color: lighten($color: $background-blue-dark, $amount: 10);
      color: white;
      border-color: $background-blue-dark;
  }
}